<template>
    <div style="display:inline;">
        <span @click="triggerModal" v-if="custom">
            <slot></slot>
        </span>
        <button type="button" v-if="!custom" @click.prevent="triggerModal" class="btn btn-outline-secondary p-2 btn-icon-sm">
            <span class="material-icons-outlined">delete</span>
        </button>

        <b-modal v-model="modal" scrollable hide-footer class="b-modal-top" @close="$emit('close')">
            <template #modal-title>
                Czy chcesz napewno usunąć?
            </template>
            <div class="h5 text-center" v-html="$props.text"></div>
            <div class="text-center mt-4">
                <button type="button" class="btn btn-danger me-2" @click.prevent="deleteConfirm">Tak</button>
                <button type="button" class="btn btn-outline-secondary" @click.prevent="modal = false">Nie</button>

            </div>
        </b-modal>
    </div>
</template>
<script>
import MagicoModal from './MagicoModal';
import axios from 'axios';

export default {
    props: ['text', 'head', 'url', 'custom'],
    components: {
        'b-modal': MagicoModal
    },
    created: function () {
        // this.selected = this.value;
        // this.items = this.$store.state.Users;
    },
    methods: {
        triggerModal: function () {
            this.modal = true;
        },
        deleteConfirm: function () {
            if (this.$props.url) {
                axios.delete(this.$props.url).then(() => {
                    this.$emit('confirm');
                    this.modal = false;
                }, error => {
                    // MAGICO_PANEL.alert('danger', 'Error');
                    console.log(error)
                    this.$emit('error');
                })
            } else {
                this.$emit('confirm');
                this.modal = false;
            }
        }
    },
    data: function () {
        return {
            items: [],
            selected: 0,
            modal: false,
        }
    },
}
</script>
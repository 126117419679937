<style>
.text-decoration-none:hover {
    text-decoration: none;
}

.dropdownselect {
    max-height: 250px;
    overflow-y: auto;
}

.dropdownselect.dropdown-menu.show::before {
    display: none;
}
</style>
<template id="index-template">
    <div class="container-xxl flex-grow-1 container-p-y">
        <!-- <div class="row g-4 mb-4">
            <div class="col-sm-6 col-xl-3">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex align-items-start justify-content-between">
                            <div class="content-left"><span>Session</span>
                                <div class="d-flex align-items-end mt-2">
                                    <h4 class="mb-0 me-2">21,459</h4><small class="text-success">(+29%)</small>
                                </div><small>Total Users</small>
                            </div><span class="badge bg-label-primary rounded p-2"><i class="bx bx-user bx-sm"></i></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-xl-3">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex align-items-start justify-content-between">
                            <div class="content-left"><span>Paid Users</span>
                                <div class="d-flex align-items-end mt-2">
                                    <h4 class="mb-0 me-2">4,567</h4><small class="text-success">(+18%)</small>
                                </div><small>Last week analytics </small>
                            </div><span class="badge bg-label-danger rounded p-2"><i
                                    class="bx bx-user-plus bx-sm"></i></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-xl-3">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex align-items-start justify-content-between">
                            <div class="content-left"><span>Active Users</span>
                                <div class="d-flex align-items-end mt-2">
                                    <h4 class="mb-0 me-2">19,860</h4><small class="text-danger">(-14%)</small>
                                </div><small>Last week analytics</small>
                            </div><span class="badge bg-label-success rounded p-2"><i class="bx bx-group bx-sm"></i></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-xl-3">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex align-items-start justify-content-between">
                            <div class="content-left"><span>Pending Users</span>
                                <div class="d-flex align-items-end mt-2">
                                    <h4 class="mb-0 me-2">237</h4><small class="text-success">(+42%)</small>
                                </div><small>Last week analytics</small>
                            </div><span class="badge bg-label-warning rounded p-2"><i
                                    class="bx bx-user-voice bx-sm"></i></span>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

    <nav class="nav nav-tabs" v-if="$router.currentRoute.name == 'index'">
        <router-link class="nav-link" to="/"
            :class="{ active: $router.currentRoute.name == 'index' && !$route.params.slownik }">
            Wszyscy
        </router-link>
        <router-link v-for="slownik, index in slownik_client_group" :key="index" class="nav-link"
            :to="'/index/' + slownik.value"
                :class="{ active: $router.currentRoute.name == 'index' && $route.params.slownik == slownik.value }">
                {{ slownik.name }}
            </router-link>
            <!--         <router-link class="nav-link" to="/create" :class="{active:$router.currentRoute.name=='create'}">
                                                Create
                                            </router-link>
                                            <router-link class="nav-link" to="/edit/1" :class="{active:$router.currentRoute.name=='edit'}">
                                                Edit
                                            </router-link>
                                            <router-link class="nav-link" to="/delete/1" :class="{active:$router.currentRoute.name=='delete'}">
                                                Delete
                                            </router-link> -->
        </nav>
        <div class="row">
            <div class="col-12 col-md-9">
                <div class="card">
                    <div class="card-header  d-flex justify-content-between">
                        <h5>
                            Klienci
                        </h5>
                        <div class="card-header-right">
                            <div class="btn btn-outline-secondary card-option">
                                <router-link to="/client/add" class="dropdown-item">
                                  Dodaj
                                </router-link>
                            <!-- <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                class="btn dropdown-toggle">
                                <i class="feather icon-more-horizontal"></i></button>
                            <ul class="list-unstyled card-option dropdown-menu dropdown-menu-right">

                                <a :href="generatePrintUrli()" class="dropdown-item">
                                    {{ $t('client.button.ods') }}
                                    </a>
                                </ul> -->
                            </div>
                        </div>
                        <!-- <div class="card-header-right">
                                                            <router-link to="/create" class="btn btn-outline-primary">
                                                                {{$t('client.button.create')}}
                                                            </router-link>
                                                            <router-link :to="'/client/ods'" class="btn btn-outline-primary">
                                                                {{$t('client.button.ods')}}
                                                            </router-link>
                                                        </div> -->
                    </div>
                    <div class="card-body">
                        <table class="table  border-top">
                            <thead>

                                <tr>
                                    <th>
                                        {{ $t('client.client_name') }}
                                    </th>
                                    <th>
                                        {{ $t('client.client_nip') }}
                                    </th>
                                    <!-- <th>
                                        {{ $t('client.client_wojewodztwo') }}
                                    </th>
                                    <th>
                                        {{ $t('client.client_want_to') }}
                                    </th>
                                    <th>
                                        {{ $t('client.client_stage') }}
                                    </th> -->
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr class="odd" v-for="item, indexx in items" :key="indexx">
                                    <td class="sorting_1">{{ item.client_name }}</td>
                                    <td>{{item.client_nip}}</td>
                                    <!-- <td>{{ slownikWojewodztwo(item.client_wojewodztwo) }}</td>
                                    <td><span class="me-2 badge badge-primary" v-for="i, index in item.g_client_want_to"
                                            :key="index">{{ i
                                            }}</span></td>
                                    <td>{{ getSlownikStage(item.client_stage) }}</td> -->
                                    <td>
                                        <div class="d-flex justify-content-end me-2">

                                            <button @click="clickShow(item)" class="btn btn-outline-secondary me-2 p-2"
                                                title="Podgląd klienta"><i
                                                    class="material-icons-outlined">person</i></button>
                                            <button @click="clickEdit(item)" class="btn btn-outline-secondary me-2 p-2"
                                                title="Edytuj"><i class="material-icons-outlined">create</i></button>

                                            <delete_confirm class="btn btn-sm btn-icon" v-if="item._links.delete"
                                                text='Usunąć klienta' :url="item._links.delete.href" @confirm="onDelete"
                                                @error="onDelete">
                                            </delete_confirm>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <nav>
                        <ul class="pagination justify-content-center">
                            <li @click.prevent="clickPage(1)"><a class="page-link">
                                    {{ $t('client.search.first') }}</a></li>
                            <li class="page-item me-1" :class="{ active: search.page == index }"
                                @click.prevent="clickPage(index)" v-for="index in search.pages" :key="index">
                                <a class="page-link" href="#"
                                    v-if="search.page != index && ((index > Number(search.page) - 5 && index <= Number(search.page)) || (index < Number(search.page) + 5 && index >= Number(search.page)))">
                                    {{ index }}
                                </a>
                                <span v-if="search.page == index" class="page-link">
                                    {{ index }}
                                </span>
                            </li>
                            <li @click.prevent="clickPage(search.pages)"><a class="page-link">
                                    {{ $t('client.search.last') }}</a></li>
                        </ul>
                    </nav>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="card">
                    <div class="card-header">
                        <h5>
                            {{ $t('client.header.search') }}
                        </h5>
                    </div>
                    <div class="card-body">
                        <form>
                            <div class="form-group mb-2">
                            <label class="form-label">
                                    {{ $t('client.client_name') }}</label>
                                <input type="text" class="form-control" v-model="search.client_name">
                            </div>
                            <div class="form-group mb-2">
                                <label class="form-label">
                                    {{ $t('client.client_nip') }}</label>
                                <input type="text" class="form-control" v-model="search.client_nip">
                            </div>
                            <!-- <div class="form-group mb-2">
                                <label class="form-label">
                                    {{ $t('client.client_type') }}</label>
                                <select class="form-control" v-model="search.client_type">
                                    <option value="">Wszystkie</option>
                                    <option v-for="i in slownik_client_type" :value="i.value" :key="i">{{ i.name }}</option>
                                </select>
                            </div> -->
                            <div class="form-group mb-2">
                                <label class="form-label">
                                    {{ $t('client.client_person') }}</label>
                                <input type="text" class="form-control" v-model="search.client_person">
                            </div>
                            <div class="form-group mb-2">
                            <label class="form-label">
                                {{ $t('client.client_user_id') }}</label>
                            <select class="form-select" v-model="search.user_id">
                                    <option value=""></option>
                                    <option v-for="i in slownik_user" :value="i.user_id" :key="i">{{ i.name }}</option>
                                </select>
                            </div>

                            <!-- <div class="form-group mb-2">
                                <label class="form-label">
                                    {{ $t('client.client_wojewodztwo') }}</label>
                              <select class="form-control" v-model="search.client_wojewodztwo">
                                                            <option value="">Wszystkie</option>
                                                            <option v-for="i in slownik_wojewodztwo" :value="i.value">{{i.name}}</option>
                                                        </select>
                                <dictionary_multiselect v-model="search.client_wojewodztwo" type="string"
                                    :data="slownik_wojewodztwo"></dictionary_multiselect>
                            </div> -->
                            <div class="form-group mb-2 ">
                                <label class="form-label">
                                    {{ $t('client.client_want_to') }}</label>
                                <select class="form-control" v-model="search.client_want_to">
                                    <option value="">Wszystkie</option>
                                    <option v-for="i in slownik_client_want_to" :value="i.value" :key="i">{{ i.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="text-end mt-4">
                                <button type="button" @click.prevent="resetSearchForm" class="btn btn-outline-warning me-2">
                                    {{ $t('client.button.reset') }}</button>
                                <button type="submit" @click.prevent="submitSearchForm" class="btn btn-outline-secondary">
                                    {{ $t('client.button.search') }}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import axios from '../../api/axios';
import DeleteConfirm from '../../components/DeleteConfirm.vue';
import api from '../../api/api';
export default {
    name: 'ClientIndex',
    components: {
        'delete_confirm': DeleteConfirm,
    },
    data: function () {
        return {
            BASE_URL: api.getBaseUrl(),
            printurl: '',
            isLoading: true,
            items: [],
            slownik_client_type: this.$store.state.slownik_client_type,
            slownik_client_want_to: this.$store.state.slownik_client_want_to,
            slownik_wojewodztwo: this.$store.state.slownik_wojewodztwo,
            slownik_client_group: this.$store.state.slownik_client_group,
            slownik_client_stage: this.$store.state.slownik_client_stage,
            slownik_user: this.$store.state.slownik_user,
            search: {
                page: 1,
                limit: 100,
                pages: 1,
                client_name: '',
                client_nip: '',
                client_regon: '',
                client_krs: '',
                client_type: '',
                client_person: '',
                client_wojewodztwo: '',
                client_want_to: '',
                client_group: '',
            },
        }
    },
    methods: {
        lang(x) {
            return x;
        },
        arrayToQueryString: function (array_in) {
            var out = new Array();

            for (var key in array_in) {
                out.push(key + '=' + encodeURIComponent(array_in[key]));
            }

            return out.join('&');
        },
        generatePrintUrli() {
            return '/panel/client/ods?' + this.arrayToQueryString(this.search);
        },
        slownikWojewodztwo(item) {
            var temp = item
            this.slownik_wojewodztwo.forEach(i => {
                if (item == i.value) temp = i.name
            })
            return temp;
        },
        getSlownikStage(item) {
            var temp = item
            this.slownik_client_stage.forEach(i => {
                if (item == i.value) temp = i.name
            })
            return temp;
        },
        convertType(item) {
            var vm = this;
            var ar = item.client_type.split('|');
            console.log(ar);
            vm.slownik_client_type.forEach(i => {
                // item.g_client_want_to[i.value] = i.name
                ar.forEach(y => {
                    if (y == i.value) item.g_client_type.push(i.name);
                })
            })
        },
        convertWantTo(item) {
            var vm = this;
            var ar = item.client_want_to.split('|');
            vm.slownik_client_want_to.forEach(i => {
                // item.g_client_want_to[i.value] = i.name
                ar.forEach(y => {
                    if (y == i.value) item.g_client_want_to.push(i.name);
                })
            })
        },
        clickPage(page) {
            this.search.page = page;
            this.getPosts();
        },
        onDelete() {
            this.getPosts();
        },
        clickShow: function (item) {
            this.$router.push('/client/' + item.client_id + '/show/');
        },
        clickEdit: function (item) {
            this.$router.push('/client/' + item.client_id + '/edit/');
        },
        getPosts: function () {
            let vm = this;
            vm.isLoading = true;
            this.search.orderby = 'client_name';
            this.search.sort = 'ASC';
            axios.get(this.BASE_URL + 'api/v1/client', {
                params: this.search
            }).then(response => {
                response.data.data.forEach(item => {
                    item.g_client_want_to = [];
                    item.g_client_type = [];
                    vm.convertWantTo(item);
                    vm.convertType(item);
                })
                vm.items = response.data.data;
                vm.search.pages = response.data.pages;
                vm.isLoading = false;
            });
        },
        submitSearchForm: function () {
            this.search.page = 1;
            this.getPosts();
        },
        resetSearchForm: function () {
            for (const property in this.search) {
                this.search[property] = '';
            }
            this.getPosts();
        }
    },
    created() {
        this.search.client_group = this.$route.params.slownik ? this.$route.params.slownik : '';
        this.getPosts();
    }
}
</script>
